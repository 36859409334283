mapboxgl.accessToken =
  "pk.eyJ1IjoiZGFuaWVsM3dtIiwiYSI6ImNsN29uMWVncTA3MjMzb254ZWZzN2RyeWoifQ.pjtD3I-6y_NBCODBaNVHnw";

const geojson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Latinerly Viborg ",
        address: "Sankt Mathias Gade 78 8800, Viborg",
      },
      geometry: {
        coordinates: [9.409719467163086, 56.44990921020508],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "LouLou Aarhus",
        address: "Åboulevarden 25, 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.20485782623291, 56.15620803833008],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Madam Cuba",
        address: "Åboulevarden 33B, 8000 Aarhus ",
      },
      geometry: {
        coordinates: [10.20485782623291, 56.15620803833008],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Bar Deco",
        address: "Ny Adelgade 5B, 1104 København",
      },
      geometry: {
        coordinates: [12.58371, 55.681229],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "London Bar Aarhus",
        address: "Åboulevarden 31 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.20485782623291, 56.15620803833008],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Zanders",
        address: "Klostertorvet 5",
      },
      geometry: {
        coordinates: [10.206318, 56.158122],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Rust Pub Randers",
        address: "Middelgade 11-13, 8900 Randers",
      },
      geometry: {
        coordinates: [10.037473, 56.458744],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Bottega Estadio",
        address: "Gunnar Nu Hansens Pl. 9, 2100 København Ø",
      },
      geometry: {
        coordinates: [12.558363, 55.669926],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Duck & Cover",
        address: "Dannebrogsgade 6, 1660 København",
      },
      geometry: {
        coordinates: [12.552392, 55.671901],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "The Bird",
        address: "Gammel Kongevej 102, 1850 Frederiksberg",
      },
      geometry: {
        coordinates: [12.544676, 55.675819],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Fox And Hounds",
        address: "Østergade 17, 7400 Herning ",
      },
      geometry: {
        coordinates: [8.978423, 56.136007],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "street",
        place_name: "Tir na nÓg",
        address: "Frederiksgade 40, 8000 Aarhus ",
      },
      geometry: {
        coordinates: [10.204958, 56.155206],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "rooftop",
        place_name: "Proud Mary",
        address: "Jomfru Ane Gade 6, 9000 Aalborg ",
      },
      geometry: {
        coordinates: [9.918883323669434, 57.05010986328125],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "street",
        place_name: "Plates Esbjerg",
        address: "Grådybet 73A 17. etage, 6700 Esbjerg",
      },
      geometry: {
        coordinates: [8.435451, 55.479041],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Force Majeure",
        address: "Borggade 5, st. tv, 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.208624, 56.159645],
        type: "Point",
      },
    },

    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "San Giovanni",
        address: "Vesterbro 46 , 9000 Aalborg",
      },
      geometry: {
        coordinates: [9.9156, 57.0477],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Cafe Evald - Brasserie & Cafe",
        address: "Papirfabrikken 10B, 8600 Silkeborg",
      },
      geometry: {
        coordinates: [9.558392524719238, 56.17272186279297],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "No Stress",
        address: "Nørregade 26, 1165 København",
      },
      geometry: {
        coordinates: [12.5713, 55.68147],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Jailhouse CPH",
        address: "Studiestræde 12, 1455 København",
      },
      geometry: {
        coordinates: [12.57003, 55.67889],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Bubbles",
        address: "Århusgade 14, 2100 København",
      },
      geometry: {
        coordinates: [12.578997, 55.705929],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "BABBA",
        address: "Nygade 19, 8600 Silkeborg",
      },
      geometry: {
        coordinates: [9.548018, 56.167296],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Nimb Copenhagen",
        address: "Bernstorffsgade 5, 1577 København",
      },
      geometry: {
        coordinates: [12.566004, 55.673606],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Little Green Door",
        address: "Gammel Strand 40, 1202 København",
      },
      geometry: {
        coordinates: [12.578394, 55.677737],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Strøm Cocktailbar",
        address: "Niels Hemmingsens Gade 32, 1153 København",
      },
      geometry: {
        coordinates: [12.575953483581543, 55.68029022216797],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Blume Cocktailbar",
        address: "Studiestræde 14A, 1455 København",
      },
      geometry: {
        coordinates: [12.569652, 55.679006],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Åbenbar",
        address: "Frimestervej 2, 2400 København",
      },
      geometry: {
        coordinates: [12.537076, 55.706537],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Zef Side",
        address: "Frederiksholms Kanal 4, 1220 København",
      },
      geometry: {
        coordinates: [12.575060844421387, 55.67596435546875],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "The Social Pub / Upstairs",
        address: "Højbro Plads 19, 1200 København",
      },
      geometry: {
        coordinates: [12.58036, 55.678091],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "The Jane",
        address: "Gråbrødretorv 8, 1154 København",
      },
      geometry: {
        coordinates: [12.575759, 55.680036],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "K-Bar",
        address: "Ved Stranden 20, 1061 København",
      },
      geometry: {
        coordinates: [12.580761, 55.67798],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Restaurant Gorilla",
        address: "Flæsketorvet 63, 1711 København",
      },
      geometry: {
        coordinates: [12.561116, 55.667711],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Side Hustle Cafe",
        address: "Studiestræde 9, 1455 København",
      },
      geometry: {
        coordinates: [12.570608, 55.678921],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gejser Cocktailbar",
        address: "Pilestræde 63, 1112 København",
      },
      geometry: {
        coordinates: [12.577618, 55.681641],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "4Tallet",
        address: "Højbro Plads 3A, 1200 København",
      },
      geometry: {
        coordinates: [12.580102920532227, 55.67829513549805],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Bar 7",
        address: "Asylgade 3, 5000 Odense",
      },
      geometry: {
        coordinates: [12.570742, 55.678964],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Tata",
        address: "Tordenskjoldsgade 15, 1055 København",
      },
      geometry: {
        coordinates: [12.587561, 55.678434],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Da Vinci Bar",
        address: "Gothersgade 19, 1123 København",
      },
      geometry: {
        coordinates: [12.583742, 55.681758],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "The Barking Dog",
        address: "Sankt Hans Gade 19, 2200 København",
      },
      geometry: {
        coordinates: [12.562966346740723, 55.68994903564453],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gaia Cocktails",
        address: "Sankt Peders Stræde 45, 1453 København",
      },
      geometry: {
        coordinates: [12.567528, 55.678729],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Chase Restaurant & Cocktailbar",
        address: "Algade 52, 4000 Roskilde",
      },
      geometry: {
        coordinates: [12.088789, 55.640533],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Y’s Café og Cocktailbar",
        address: "Nørre Voldgade 102, 1358 København",
      },
      geometry: {
        coordinates: [12.572442054748535, 55.68338394165039],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Rabalder Bar",
        address: "Gothersgade 33B, 1123 København",
      },
      geometry: {
        coordinates: [12.582293, 55.682149],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Restaurant Noi (Nobis Hotel)",
        address: "H.C. Andersens Blvd. 34, 1574 København",
      },
      geometry: {
        coordinates: [12.573652, 55.672514],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Restaurant Pluto",
        address: "Borgergade 16, 1300 København K",
      },
      geometry: {
        coordinates: [12.58524, 55.683514],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "1656",
        address: "Gasværksvej 33, 1656 København",
      },
      geometry: {
        coordinates: [12.559670448303223, 55.68526840209961],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "1105",
        address: "Kristen Bernikows Gade 4, 1105 København",
      },
      geometry: {
        coordinates: [12.582178, 55.68047],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "La Petite Mort",
        address: "Vesterbrogade 47, 1620 København",
      },
      geometry: {
        coordinates: [12.555615, 55.671996],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Curfew",
        address: "Vesterbrogade 20, 1620 København V",
      },
      geometry: {
        coordinates: [12.558615684509277, 55.672943115234375],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gedulgt Aarhus",
        address: "Fredensgade 41, 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.208608627319336, 56.152854919433594],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gedulgt Aalborg",
        address: "Maren Turis Gade, 9000 Aalborg",
      },
      geometry: {
        coordinates: [9.921074867248535, 57.04975891113281],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gedulgt Aarhus",
        address: "Fredensgade 41, 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.208608627319336, 56.152854919433594],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Zanders",
        address: "Klostertorvet 5, 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.206318, 56.158122],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Doktor Herman",
        address: "Gothersgade 59, 7000 Fredericia",
      },
      geometry: {
        coordinates: [9.756643295288086, 55.56404495239258],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Piano Bar Herning",
        address: "Østergade 25, 7400 Herning",
      },
      geometry: {
        coordinates: [8.979345, 56.135874],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Visit",
        address: "Sankt Gertruds Stræde 5, 5000 Odense",
      },
      geometry: {
        coordinates: [10.385210037231445, 55.39633560180664],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Billund Gastro Pub",
        address: "Butikstorvet 10H, 7190 Billund",
      },
      geometry: {
        coordinates: [9.115716, 55.730015],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Storms Pakhus",
        address: "Lerchesgade 4, 5000 Odense",
      },
      geometry: {
        coordinates: [10.38301, 55.403873],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Pizzeria Luca",
        address: "Gammel strand 42, 1202 København ",
      },
      geometry: {
        coordinates: [12.578531265258789, 55.67766571044922],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Trattoria Luca ",
        address: "Gammel Jernbanevej 20, 2800 Kongens lyngby ",
      },
      geometry: {
        coordinates: [12.504794120788574, 55.768836975097656],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Mamma’s",
        address: "Regnbuepladsen 3-5, 1462 Copenhagen V",
      },
      geometry: {
        coordinates: [12.571311950683594, 55.67579650878906],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Oscar Bar & Café",
        address: "Regnbuepladsen 9, 1550 København V",
      },
      geometry: {
        coordinates: [12.571633, 55.675738],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Restaurant Mark",
        address: "Axeltorv 3, 1609 København V",
      },
      geometry: {
        coordinates: [12.564111, 55.675659],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Broens Gadekøkken",
        address: "Strandgade 95, 1401 København K",
      },
      geometry: {
        coordinates: [12.596882, 55.677947],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Toldboden Seaside ",
        address: "Strandgade 95, 1401 København K",
      },
      geometry: {
        coordinates: [12.5993254, 55.6901304],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Reffen - Drueta Bar",
        address: "Refshalevej 167A, 1432 København K",
      },
      geometry: {
        coordinates: [12.609697, 55.693118],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Werkstatt",
        address: "Refshalevej 167A, 1432 København K",
      },
      geometry: {
        coordinates: [12.609697, 55.693118],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Strandens Brasserie",
        address: "Kongsmarkvej 2, 4200 Slagelse",
      },
      geometry: {
        coordinates: [11.209496, 55.442142],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Il Rosmarino",
        address: "Nyhavn 71, 1051 København K",
      },
      geometry: {
        coordinates: [12.593581, 55.679349],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "L’Appetito",
        address: "Vester Farimagsgade 9, 1612 Copenhagen V",
      },
      geometry: {
        coordinates: [12.562097549438477, 55.676414489746094],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Galionen",
        address: "Nyhavn 23, 1051 Copenhagen K",
      },
      geometry: {
        coordinates: [12.588972091674805, 55.68055725097656],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Cocks & Cows",
        address: "Gammel Strand 34, 1202 København K",
      },
      geometry: {
        coordinates: [12.578815, 55.677815],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Cocks & Cows SP34",
        address: "Sankt Peders Stræde 38, 1453 Copenhagen K",
      },
      geometry: {
        coordinates: [12.568979263305664, 55.679134368896484],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Cocks & Cows",
        address: "Lyngby Hovedgade 78, 2800 Kongens Lyngby",
      },
      geometry: {
        coordinates: [12.505401, 55.768878],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Cocks & Cows CPH Airport ",
        address: "Vestvej, 2770 Kastrup",
      },
      geometry: {
        coordinates: [12.643072128295898, 55.629180908203125],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Comwell Aalborg Bar V",
        address: "Vesterbro 2, 9000 Aalborg",
      },
      geometry: {
        coordinates: [9.911568, 57.042163],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Comwell Aalborg Bar V",
        address: "Vesterbro 2, 9000 Aalborg",
      },
      geometry: {
        coordinates: [9.911568, 57.042163],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "First Grand Hotel",
        address: "Jernbanegade 18, 5000 Odense C",
      },
      geometry: {
        coordinates: [10.385074, 55.397106],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Barstart",
        address: "Fiskergade 28, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.207827, 56.155753],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Den Fede Drue",
        address: "Dagmar Petersens Gade 62, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.22287, 56.164937],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Grace cocktailbar",
        address: "Skolegade 26, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.211528, 56.155164],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "25hours Hotel",
        address: "Pilestræde 65, 1112 København",
      },
      geometry: {
        coordinates: [12.577096, 55.681665],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "No Stress Odense",
        address: "Mageløs 1, 5000 Odense",
      },
      geometry: {
        coordinates: [10.385474, 55.394484],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "LouLou Aarhus",
        address: "Åboulevarden 25, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.199338, 56.1552617],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Madam Cuba",
        address: "Åboulevarden 33b, 8000 Aarhus",
      },
      geometry: {
        coordinates: [10.208661, 56.155862],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Tørst Cocktailbar",
        address: "Havnevej 11, 9990 Skagen",
      },
      geometry: {
        coordinates: [10.589692, 57.721097],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Struktur",
        address: "Vesterå 4, 9000 Aalborg",
      },
      geometry: {
        coordinates: [9.91801, 57.049734],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Café Viggo",
        address: "Åboulevarden 52, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.20808, 56.156519],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Grappa Piccolo",
        address: "Åboulevarden 62, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.207462, 56.156826],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Grappa",
        address: "Åboulevarden 60, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.20485782623291, 56.15620803833008],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gedulgt Århus",
        address: "Fredensgade 41, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.208675, 56.152685],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Gedulgt Aalborg",
        address: "Maren Turis Gade, 9000 Aalborg",
      },
      geometry: {
        coordinates: [9.9211022, 57.0497957],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Bar Rar",
        address: "Dronningensgade 2B, 5000 Odense C",
      },
      geometry: {
        coordinates: [10.379147, 55.39796],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Grappa Piccolo Århus Ø",
        address: "Irma Pedersens Gade 82, 8000 Aarhus C",
      },
      geometry: {
        coordinates: [10.22608, 56.16378],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "Grappa Piccolo Risskov",
        address: "Nordre Strandvej 39, 8240 Risskov",
      },
      geometry: {
        coordinates: [10.246098, 56.194602],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        accuracy: "point",
        place_name: "UBN Odense",
        address: "Asylgade 3, 5000 Odense C",
      },
      geometry: {
        coordinates: [10.387225, 55.396672],
        type: "Point",
      },
    },
  ],
};

const map = new mapboxgl.Map({
  container: "map",
  style: "mapbox://styles/daniel3wm/cl7kfs19s005816lfnum9rxrx",
  center: [12.567304, 55.676265],
  zoom: 6,
});

// code from the next step will go here!

// add markers to map
for (const feature of geojson.features) {
  // create a HTML element for each feature
  const el = document.createElement("div");
  el.className = "marker";

  // make a marker for each feature and add it to the map
  new mapboxgl.Marker(el)
    .setLngLat(feature.geometry.coordinates)
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<h3 class="text-xl">${feature.properties.place_name}</h3><p class="text-xs" style="font-size:.65rem">${feature.properties.address}</p>`
        )
    )
    .addTo(map);
}
