$(document).ready(function () {
  // Hamburger menu

  $(".menu").hide();
  $(".mobile-nav").removeClass("hidden");
  $(".icon").click(function () {
    $(".menu").slideToggle("slow");
  });

  // When the button is clicked make the lightbox fade in in the span of 1 second, hide itself and start the video
  $("#button").on("click", function () {
    $("#lightbox").fadeIn(1000);
    $(this).hide();
    var videoURL =
      "https://www.youtube.com/embed/NFZ4iDuhvFM?si=f1ftC_Pgo-KI5soD";
    $("#video").prop("src", videoURL);
  });

  $("#button2").on("click", function () {
    $("#lightbox").fadeIn(1000);
    $(this).hide();
    var videoURL =
      "https://player.vimeo.com/video/747628554?h=c6b053d6ef&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
    $("#video").prop("src", videoURL);
  });

  // When the close button is clicked make the lightbox fade out in the span of 0.5 seconds and show the play button
  $("#close-btn").on("click", function () {
    $("#lightbox").fadeOut(500);
    $("#button").show(250);
    $("#button2").show(250);
    $("#video").prop("src", "");
  });

  var imageContainer = new hoverEffect({
    parent: document.querySelector(".half-image"),
    intensity: -0.55,
    //imagesRatio: 512 / 768,
    image1:
      "https://res.cloudinary.com/three-wise-monkeys/image/upload/v1662648055/3%20Wise%20Monkeys/esben_vvefh1.jpg",
    image2:
      "https://res.cloudinary.com/three-wise-monkeys/image/upload/v1694595352/svitlana-_VGRR4y76uY-unsplash_1_nfcnkm.jpg",
    displacementImage: "assets/campari-13.jpg",
    hover: false,
  });
  document
    .querySelector(".next")
    .addEventListener("mouseover", imageContainer.next);
  document
    .querySelector(".next")
    .addEventListener("mouseout", imageContainer.previous);
});
